import { FC, useMemo } from "react";
import { NodeProps } from "./Node.type";
import {
    StyledNodeActivities,
    StyledNodeApplication,
    StyledNodeContainer,
    StyledNodeContent,
    StyledNodeContentCaption,
    StyledDiscussionIcon,
    StyledNodeContentName,
    StyledRelationshipsIcon,
    StyledNodeIcon,
    StyledNodeWrapper,
    StyledTextContent,
    StyledNumber,
    StyledPeople,
    StyledShowAll,
    StyledNodeContentNote,
    StyledExpiredLabel
} from "./Node.styled";
import { CaptionDot } from "./components/CaptionDot/CaptionDot.component";
import { Important } from "./components/Important/Important.component";
import { Actions } from "./components/Actions/Actions.component";
import { getLinkName } from "@/utils/v3/GetLinkName/GetLinkName.util";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { ActivityMessageOrComment } from "../ActivityMessageOrComment/ActivityMessageOrComment.component";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { useShowMore } from "@/hooks/v3/UseShowMore/UseShowMore.hook";
import { NodeTypeType } from "@/types/Node/Node.type";
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { isItemExpired } from "@/utils/v3/IsItemExpired/IsItemExpired.util";
import { ExpiredTip } from "../../Other/ExpiredTip/ExpiredTip.component";
import { useRouter } from "@/providers/Router/Router.provider";

export const Node: FC<NodeProps> = (props) => {
    const {
        node,
        users,
        size = 'large',
        className,
        activitiesWithComments,
        after,
        relationships,
        importantUserIds,
        useSkeleton = true,
        onClick: onClickProp
    } = props;
    const { setActiveNodeId } = useNode();
    const { followedNodes, invitedUsers, confirmedInvitedUsers, user } = useMe();
    const isMobile = useRenederBelow('tablet');
    const isFollowed = useMemo(() => {
        // for static nodes start
        if (node.id < 0) {
            return true;
        }
        // for static nodes end
        return !!followedNodes.find((followedNode) => followedNode.id === node.id);
    }, [followedNodes, node]);
    const { items, showMore, setShowMore } = useShowMore({ items: activitiesWithComments || [], limit: 5 });
    const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
    const isNodeExpired = isItemExpired(node.createdAt) && !node.sampleData && isIndividual;
    const activitiesWithCommentsJsx = items.map((activityOrComment, i) => {
        if ('createdAt' in activityOrComment) {
            return <ActivityMessageOrComment useSkeleton={useSkeleton} isExpired={isNodeExpired} comment={activityOrComment} users={users} key={i} />
        } else {
            return <ActivityMessageOrComment useSkeleton={useSkeleton} isExpired={isNodeExpired} activityMessage={activityOrComment} users={users} key={i} />
        }
    });
    const shouldRenerActivities = size !== 'small' && !!activitiesWithComments?.length;
    const name = getLinkName(node.name);
    const router = useRouter();
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const menuElements = document.querySelectorAll(".MuiMenu-root");
        for (let i = 0; i < menuElements.length; i++) {
            if (menuElements[i].contains(e.target as Node)) {
                return;
            }
        }
        const dialogElements = document.querySelectorAll(".MuiDialog-root:not(.search-modal)");
        for (let i = 0; i < dialogElements.length; i++) {
            if (dialogElements[i].contains(e.target as Node)) {
                return;
            }
        }
        const confirmationElement = document.getElementById("confirmation-dialog");
        if (confirmationElement && confirmationElement.contains(e.target as Node)) {
            return;
        }
        if ((e.target as Element).closest('.ignore--node-actions')) {
            return;
        }
        if (onClickProp) {
            onClickProp(node);
        } else {
            if (isNodeExpired) {
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: 'payment',
                        plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                    }
                });
            } else {
                setActiveNodeId(node.id)
            }
        }
    };
    const people = useMemo(() => {
        if (!relationships) {
            return []
        }
        return relationships.actors
            .filter(actor => !!actor.user && !!actor.user.avatar)
            .map(actor => {
                const user = actor.user!;
                return {
                    user,
                    actionType: actor.actionType,
                };
            });
    }, [relationships]);
    const importantUsers = importantUserIds.map((userId) => {
        const user = users.find((user) => user.id === userId);
        return user!
    }).filter(el => !!el)
    const isNote = node.nodeType === NodeTypeType.NOTE;
    return <StyledNodeWrapper size={size} className={`${className} disable-node-drawer-close`} onClick={onClick}>
        <StyledNodeContainer size={size} isNote={isNote}>
            <StyledNodeContent>
                {!isNote && <StyledNodeIcon node={node} useSkeleton={useSkeleton} />}
                <StyledTextContent>
                    {!isNote && <StyledNodeContentName>{name}</StyledNodeContentName>}
                    {isNote && <StyledNodeContentNote>
                        {node.content && parse(sanitizeHtml(node.content, {
                            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['a']),
                            allowedAttributes: {
                                ...sanitizeHtml.defaults.allowedAttributes,
                                'a': ['href', 'title', 'target']
                            }
                        }))}
                    </StyledNodeContentNote>}
                    
                    {size==='large' && isMobile && <StyledPeople useSkeleton={useSkeleton} isExpired={isNodeExpired} people={people} />}
                    <StyledNodeContentCaption>
                        {isNodeExpired && <StyledExpiredLabel>Upgrade your plan</StyledExpiredLabel>}
                        {!isNodeExpired && <>
                            {(isFollowed || !!importantUsers.length) && <>
                                <Important useSkeleton={useSkeleton} isExpired={isNodeExpired} size={size} users={importantUsers} />
                                <CaptionDot />
                            </>}
                            {!!node.relationshipNumber && <>
                                <StyledRelationshipsIcon />
                                <StyledNumber useSkeleton={useSkeleton} isExpired={isNodeExpired}>{node.relationshipNumber}</StyledNumber>
                                <CaptionDot />
                            </>}
                            {!!node.activitiesNumber && <>
                                <StyledDiscussionIcon />
                                <StyledNumber useSkeleton={useSkeleton} isExpired={isNodeExpired}>{node.activitiesNumber}</StyledNumber>
                                <CaptionDot />
                            </>}
                            <StyledNodeApplication useSkeleton={useSkeleton} isExpired={isNodeExpired}>{node.application}</StyledNodeApplication>
                        </>}
                        
                    </StyledNodeContentCaption>
                </StyledTextContent>
            </StyledNodeContent>
            {!isNodeExpired && <Actions {...props} people={people}  />}
            {isNodeExpired && <ExpiredTip />}
        </StyledNodeContainer>
        {shouldRenerActivities && <StyledNodeActivities>
            {activitiesWithCommentsJsx}
            {!showMore && activitiesWithComments.length > items.length && <StyledShowAll className="ignore--node-actions" variant='tertiary' onClick={() => setShowMore(true)}>Show all</StyledShowAll>}
        </StyledNodeActivities>}
        {after}
    </StyledNodeWrapper>
}