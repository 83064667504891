import { HttpClient } from "@/axios/HttpClient";
import { SearchGetQueryParams } from "./Types/Get/Get.type";
import { envUrl } from "@/service/helpers";
import { SearchGetSmartQueryParams } from "./Types/GetSmart/GetSmart.type";

// Оновлений метод із підтримкою AbortController
export const SearchGetFetch = (query: SearchGetQueryParams, signal?: AbortSignal) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/search`, {
        params: query,
        signal,
    });
};

export const SearchSmartGetFetch = (query: SearchGetSmartQueryParams, signal?: AbortSignal) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/search/smart`, {
        params: query,
        signal,
    });
};