import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { SearchInputWrapper, StyledClearButton, StyledDialogLayout, StyledLoaderWrapper, StyledSearchButton } from "./SearchModal.styled";
import { useIsModalOpened } from "@/hooks/v3/UseIsModalOpened/UseIsModalOpened.hook";
import { StyledLoaderIconWrapper, StyledSearchIcon, StyledSearchIconWrapper } from "../../Layouts/MainLayout/components/Header/components/Search/components/SearchTextField/SearchTextField.styled";
import { Input } from "../../Fields/Input/Input.component";
import { Summary } from "./components/Summary/Summary.component";
import { RelatedPeople } from "./components/RelatedPeople/RelatedPeople.component";
import { SearchSmartGetFetch } from "@/axios/AiService/Serach/Serach.api";
import { NodeType } from "@/components/v3/List/Node/Node.type";
import { PersonType } from "@/components/v3/List/Person/Person.type";
import { RelatedResources } from "./components/RelatedResources/RelatedResources.component";
import { Loader } from "../../Other/Loader/Loader.component";
import { CloseIcon } from "@/icons/v3/close.icon";

export const SeachModal: FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [queryToSearchBy, setQueryToSearchBy] = useState<string>('');
    const [answer, setAnswer] = useState<string>('');
    const [listOfNodes, setListOfNodes] = useState<NodeType[]>([]);
    const [listOfPeople, setListOfPeople] = useState<PersonType[]>([]);
    const { value, onChange: onChangeIsOpened } = useIsModalOpened({ name: 'search' });
    const abortControllerRef = useRef<AbortController | null>(null);

    const onChange = (value: boolean) => {
        onChangeIsOpened(value);
    };
    const loadData = async () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    
        const controller = new AbortController();
        abortControllerRef.current = controller;
    
        setIsLoading(true);
    
        try {
            const res = await SearchSmartGetFetch(
                { query: queryToSearchBy }, 
                controller.signal
            );
    
            setListOfNodes(res.data.nodes);
            setAnswer(res.data.answer || '');
            setListOfPeople(res.data.users || []);
        } catch (error: any) {
            if (error.name !== 'AbortError') {
                console.error("Error fetching data:", error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const clear = () => {
        setQueryToSearchBy('');
        // setIsVisible(false);
        setListOfNodes([]);
        setListOfPeople([]);
        setAnswer('');
        setIsLoading(false);
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    };
    useEffect(() => {
        if (!queryToSearchBy) {
            clear();
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryToSearchBy]);
    useEffect(() => {
        if (!value) {
            clear();
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    
    const handleChange = useCallback ((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const searchTerm = event.target.value;
        setQueryToSearchBy(searchTerm);
    }, []);
    const displayRecentSearches = !queryToSearchBy || (!listOfPeople.length && !listOfNodes.length && !answer);
    const topPx = 120;
    const displayResults = listOfNodes.length || listOfPeople.length || answer;
    return <Dialog topPx={120} value={value} onChange={onChange} className="search-modal">
        <StyledDialogLayout maxHeight={`calc(100vh - 64px - ${topPx}px)`}>
            <SearchInputWrapper>
                <Input
                    placeholder="Search in Sense"
                    variant="filled"
                    size='medium'
                    value={queryToSearchBy}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            loadData();
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <>
                                {(!displayResults || !isLoading) && <StyledSearchIconWrapper>
                                    <StyledSearchIcon />
                                </StyledSearchIconWrapper>}
                                {!!displayResults && isLoading && <StyledLoaderIconWrapper>
                                    <Loader size={16} />
                                </StyledLoaderIconWrapper>}
                            </>
                        ),
                        endAdornment: <>
                            {!!queryToSearchBy && <StyledClearButton variant="tertiary" onClick={() => setQueryToSearchBy('')} icon={<CloseIcon />} />}
                        </>,
                    }}
                    autoFocus
                />
                {!!queryToSearchBy && <StyledSearchButton size="large" onClick={() => loadData()} >Search</StyledSearchButton>}
            </SearchInputWrapper>
            {isLoading && !displayResults && <StyledLoaderWrapper>
                <Loader size={48} />
            </StyledLoaderWrapper>}
            {!displayRecentSearches && <>
                <Summary value={answer} />
                <RelatedPeople listOfPeople={listOfPeople} />
                <RelatedResources listOfNodes={listOfNodes} onUpdate={loadData} />
            </>}
            {/* {displayRecentSearches && <>
                <RecentSearches />
            </>} */}
        </StyledDialogLayout>
    </Dialog>
};