import { FC, useMemo } from "react";
import { StyledWrapper, StyledFiltersContainer, StyledSelect, StyledRelationsFiltersWrapper, StyledFiltersWrapper } from "../AllResources/AllResources.styled";
import { useSpacePage } from "@/providers/v3/SpacePage/SpacePage.provider";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { WalkthroughWrapper } from "@/providers/WalkthroughProvider/components/WalkthroughWrapper/WalkthroughWrapper.component";
import moment from "moment";
import { ActivityGroup } from "@/components/v3/List/ActivityGroup/ActivityGroup.component";
import { SpacesNodeWrapperType } from "@/providers/v3/SpacePage/SpacePage.type";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { findLastActor } from "@/components/v3/List/Activity/Activity.utils";
import { RelationCheckboxButton } from "./components/RelationCheckboxButton/RelationCheckboxButton.component";
import { RelationActionType } from "@/types/Relations/Relations.type";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { useFeatureFlags } from "@/providers/FeatureFlags/FeatureFlags.provider";
import { getActivityRelations, getActivityValue, listOfActivityOptions } from "@/utils/v3/ActivityRelations/ActivityRelations.utils";
import { AddButton } from "../AddButton/AddButton.component";

export const Activities: FC = () => {
    const {
        listOfSpaceUsers,
        listOfSpaceApplications,
    } = useSpacePageCommon();
    const { useNewActivityUI } = useFeatureFlags();
    const { isShared, isSharedSpace } = useIsShared();
    const {
        update,
        selectedRelations,
        setSelectedRelations,
        selectedPeople,
        setSelectedPeople,
        selectedApps,
        setSelectedApps,
        isFetchingRecentActivities,
        listOfRecentActivities,
    } = useSpacePage();
    const groupedNodes = useMemo((): SpacesNodeWrapperType[][] => {
      const groupsMap = new Map();
      listOfRecentActivities.forEach(wrapper => {
        const lastActor = findLastActor(wrapper.relationships?.actors || []);
        const happenedAtDate = lastActor?.properties.happenedAt || wrapper.node.createdAt;
        const momentDate = moment(happenedAtDate).format('YYYY-MM-DD');
        if (!groupsMap.has(momentDate)) {
          groupsMap.set(momentDate, []);
        }
        groupsMap.get(momentDate).push({
          ...wrapper,
          lastActor
        });
      });
      return Array.from(groupsMap.values());
    }, [listOfRecentActivities]);

    const groupsJsx = groupedNodes.map((group, i) => {
      return <ActivityGroup
        key={i}
        date={group[0].lastActor?.properties.happenedAt || group[0].node.createdAt}
        group={group.map((wrapper) => {
          return {
            node: wrapper.node,
            activities: wrapper.activities,
            relationships: wrapper.relationships,
            users: listOfSpaceUsers,
            onUpdate: update,
            importantUserIds: wrapper.importantUserIds,
            lastActor: wrapper.lastActor
          }
        })}
      />
    })
    const setSelectedRelationsHandler = (val: string[]) => {
      const newVal = getActivityRelations(val)
        setSelectedRelations(newVal);
    };
    const activityValue = useMemo(() => {
        return getActivityValue(selectedRelations)
    }, [selectedRelations]);
    return <LoaderLayout isLoading={isFetchingRecentActivities && !listOfRecentActivities.length}>
      <StyledWrapper>
        {!useNewActivityUI && <>
          {!isShared && <StyledRelationsFiltersWrapper>
            <RelationCheckboxButton val={[RelationActionType.MENTIONS, RelationActionType.CC, RelationActionType.BCC]} value={selectedRelations} onChange={setSelectedRelations}>Mentioned you</RelationCheckboxButton>
            <RelationCheckboxButton val={[RelationActionType.ASSIGNEE]} value={selectedRelations} onChange={setSelectedRelations}>Assigned to you</RelationCheckboxButton>
            <RelationCheckboxButton val={[RelationActionType.UPDATED]} value={selectedRelations} onChange={setSelectedRelations}>Task status changed</RelationCheckboxButton>
            <RelationCheckboxButton val={[RelationActionType.COMMENTED_BY, RelationActionType.COMMENTER, RelationActionType.POSTED_BY]} value={selectedRelations} onChange={setSelectedRelations}>Commented</RelationCheckboxButton>
            <RelationCheckboxButton val={[RelationActionType.CREATED_BY, RelationActionType.CREATOR]} value={selectedRelations} onChange={setSelectedRelations}>Created</RelationCheckboxButton>
            <RelationCheckboxButton val={[RelationActionType.EDITED_BY]} value={selectedRelations} onChange={setSelectedRelations}>Edited</RelationCheckboxButton>
            <RelationCheckboxButton val={[RelationActionType.SHARED_TO, RelationActionType.SHARED_BY]} value={selectedRelations} onChange={setSelectedRelations}>Shared</RelationCheckboxButton>
            <RelationCheckboxButton val={[RelationActionType.FROM]} value={selectedRelations} onChange={setSelectedRelations}>Sent to you</RelationCheckboxButton>
            <RelationCheckboxButton val={[RelationActionType.ADDED_BY]} value={selectedRelations} onChange={setSelectedRelations}>Added manually</RelationCheckboxButton>
          </StyledRelationsFiltersWrapper>}
        </>}
        <StyledFiltersWrapper>
          <StyledFiltersContainer>
            {useNewActivityUI && <StyledSelect
                label="Activity"
                value={activityValue}
                onChange={(val: any) => setSelectedRelationsHandler(val)}
                options={listOfActivityOptions}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                icon={<FilterIcon />}
            />}
            <StyledSelect
                label="Apps"
                value={selectedApps}
                onChange={(val: any) => setSelectedApps(val)}
                options={listOfSpaceApplications.map((app) => {
                  return { label: `${app.name}`, value: app.id}
              })}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                icon={<FilterIcon />}
            />
            <WalkthroughWrapper topContainerMargin={56} variant="walkthrough-updates" title="All updates at one place" description={<>
            <div>Project-related recent discussions, decisions, updated resources, tasks, etc. </div>
            <div>Use filters to quickly get what you are looking for.</div>
            </>} targetId="walkthrough-updates" >
                <StyledSelect
                    label="People"
                    value={selectedPeople}
                    onChange={(val: any) => setSelectedPeople(val)}
                    options={listOfSpaceUsers.map((user) => {
                        return { label: `${user.firstName} ${user.lastName}`, value: user.id, img: user.avatar || "/icons/no_avatar.svg"}
                    })}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    icon={<FilterIcon />}
                    id="walkthrough-updates"
                />
            </WalkthroughWrapper>
          </StyledFiltersContainer>
          {useNewActivityUI && <>
            {!isSharedSpace && <AddButton />}
          </>}
        </StyledFiltersWrapper>
        {groupsJsx}
      </StyledWrapper>
    </LoaderLayout>
};