import { Button } from "@/components/v3/Fields/Button/Button.component";
import { FC, useMemo } from "react";
import { StyledSectionHeader, StyledWrapper } from "./RecentUpdates.styled";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useForYouPage } from "@/providers/v3/ForYouPage/ForYouPage.provider";
import { ActivityTypeWithUser } from "@/providers/v3/ForYouPage/ForYouPage.type";
import { ActivityGroup } from "@/components/v3/List/ActivityGroup/ActivityGroup.component";
import moment from "moment";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { findLastActor } from "@/components/v3/List/Activity/Activity.utils";

export const RecentUpdates: FC = () => {
    const { isFetchingRecentActivities, listOfActivitiesDetailsWithUser, setActiveTab, update } = useForYouPage();
    const { users } = useMe();
    const groupedNodes = useMemo((): ActivityTypeWithUser[][] => {
        const groupsMap = new Map();
        listOfActivitiesDetailsWithUser.forEach(wrapper => {
            const lastActor = findLastActor(wrapper.relationships?.actors || []);
            const happenedAtDate = lastActor?.properties.happenedAt || wrapper.node.createdAt;
            const momentDate = moment(happenedAtDate).format('YYYY-MM-DD');
            if (!groupsMap.has(momentDate)) {
                groupsMap.set(momentDate, []);
            }
            groupsMap.get(momentDate).push({
                ...wrapper,
                lastActor
            });
        });
        return Array.from(groupsMap.values());
      }, [listOfActivitiesDetailsWithUser]);
      
    const groupsJsx = groupedNodes.map((group, i) => {
        return <ActivityGroup
            key={i}
            date={group[0].lastActor?.properties.happenedAt || group[0].node.createdAt}
            group={group.map((wrapper) => {
                return {
                    node: wrapper.node,
                    activities: wrapper.messages,
                    relationships: wrapper.relationships,
                    users: users,
                    onUpdate: update,
                    importantUserIds: wrapper.importantUserIds,
                    lastActor: wrapper.lastActor
                }
            })}
        />
    })
    const isEmptySpace = !listOfActivitiesDetailsWithUser.length && !isFetchingRecentActivities
    return <>
        {!isEmptySpace && <StyledWrapper>
            <StyledSectionHeader title="Recently updated" >
                <Button onClick={() => setActiveTab('ACTIVITIES')} variant="tertiary">Show all</Button>
            </StyledSectionHeader>
            <LoaderLayout isLoading={isFetchingRecentActivities} transparent={!!groupsJsx.length}>
                {groupsJsx}
            </LoaderLayout>
        </StyledWrapper>}
    </>
};